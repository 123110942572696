import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDVCDc0zpZ6JBkyCynxgAoUk5Cp2fq-MOI",
  authDomain: "santa-ai-prod.firebaseapp.com",
  projectId: "santa-ai-prod",
  storageBucket: "santa-ai-prod.appspot.com",
  messagingSenderId: "1018594888581",
  appId: "1:1018594888581:web:b9a591c04b20e60595d2ec",
  measurementId: "G-ST9TJTFCQ2",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
